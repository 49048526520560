@import "../../../theme/index.module.scss";

.container {
  position: relative;

  @include bp($larger) {
    width: 100%;
  }

  .MenuButton {
    z-index: 3;
    position: relative;
  }
}

.UserMenuListItem {
  background: #E60201;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em 0;

  button {
    background: transparent;
    padding: 0;
  }

  @include bp($larger) {
    padding: 0;
    order: 5;
    background: transparent;
    margin-left: 1.5em;
  }
}

.NavigationContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  right: -100%;
  z-index: 2;
  padding-top: 4.75em;
  transition: right 0.3s;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul {
  }

  &.opened {
    right: 0;
  }

  @include bp($larger) {
    position: relative;
    padding: 0;
    right: auto;
    background: transparent;

    ul {
      justify-content: flex-end;
    }
  }
}

.NavigationList {
  height: 100%;
  overflow: auto;
  padding-top: 1.25em;

  @include bp($larger) {
    overflow: unset;
    display: flex;
  }
}


